import React from 'react';
import Layout from '../../components/Layout';
import { withIntl } from '../../i18n';
import FormPage from '../../templates/FormPage';
const host = process.env.GATSBY_HOST;

const certificationCorp = {
  title: 'certificationCorpTitle',
  subTitle: 'certificationCorpSubTitle',
  form: {
    title: '',
    type: 'certificationCorp',
    fields: [
      {
        type: 'firstName',
        label: 'formFirstName',
        value: ''
      },
      {
        type: 'lastName',
        label: 'formLastName',
        value: ''
      },
      {
        type: 'email',
        label: 'formEmail',
        value: ''
      },
      {
        type: 'company',
        label: 'formCompany',
        value: ''
      },
      {
        type: 'message',
        label: 'formMessage',
        value: ''
      },
    ],
    button: {
      text: 'formSend',
      link: `${host}/api/1/json/public/513644/6d85995d468d2cd7d26930393473dff049842412`,
    }
  }
};

class CertificationCorp extends React.PureComponent {
  render() {
    return (
      <Layout>
        <FormPage
          data={certificationCorp}
        />
      </Layout>
    );
  }
}

export default withIntl(CertificationCorp);

